(function() {
  'use strict';
  angular
    .module('skynurses', [
      'ngTouch',
      'ngSanitize',
      'ngMessages',
      'ui.router',
      'ui.bootstrap',
      'toastr',
      'formio',
      'ngFormioGrid',
      'ngFormioHelper',
      'ngMap',
      'angular-bind-html-compile'
    ]);
})();

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('acceptClinicianResource', ['BaseController', function(BaseController) {
    var AcceptClinicianController = BaseController('acceptClinician');
    return {
      $get: function() { return null; },
      parent: 'trip',
      base: 'trip.',
      templates: {
        create: 'views/tripapply/create.html'
      },
      controllers: _.assign(AcceptClinicianController.controllers, {
        index: [
          '$rootScope',
          '$scope',
          '$state',
          '$controller',
          function($rootScope, $scope, $state, $controller) {
            $scope.query = $scope.query || { sort: '-created' };
            return $controller(AcceptClinicianController.methods.index, {$scope: $scope});
          }
        ],
        create: [
          '$rootScope',
          '$scope',
          '$state',
          function($rootScope, $scope, $state) {
            $scope.submission = {};

            $rootScope.whenReady.then(function() {
              $scope.submission = {
                data: {
                  clinician: $rootScope.user
                }
              };
            });
            $scope.hideComponents = ['clinician'];
            $rootScope.currentResource = $scope.currentResource;
            $scope.$on('formSubmission', function () {
              $state.go('tripIndex');
            });
            $scope.form = {};
            return {handle: true};
          }
        ]
      })
    };
  }]);

/**

 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('UserResource', function() {
    return {
      $get: function() { return null; },

      /**
       * Allow for nested resources by setting the parent to another resource.
       */
      parent: null,

      /**
       * Allow you to change the template for any view of this resource.
       */
      templates: {
        index: '',
        view: '',
        create: '',
        edit: '',
        delete: ''
      },

      /**
       * Provide customer parameters to each of the operations for this resource.
       */
      params: {
        index: {},
        view: {},
        create: {},
        edit: {},
        delete: {}
      },

      /**
       * Provide custom controllers for each operation on a resource.
       */
      controllers: {
        index: null,
        abstract: null,
        view: null,
        create: ['$scope', '$log', function($scope, $log) {
          $scope.$on('formSubmission', function(err, submission) {
            // A submission has been made... Do something...
            $log.debug(submission);
          });
        }],
        edit: ['$scope', '$log', function($scope, $log) {
          $scope.$on('formSubmission', function(err, submission) {
            // A submission was updated... Do something...
            $log.debug(submission);
          });
        }],
        delete: ['$scope', '$log', '$stateParams', function($scope, $log) {
          $scope.$on('delete', function() {
            // A submission was deleted.
            $log.debug('Submission Deleted');
          });
        }]
      }
    };
  });

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('TripApplyResource', ['BaseController', function(BaseController) {
    var TripApplyController = BaseController('tripApply');
    return {
      $get: function() { return null; },
      parent: 'trip',
      base: 'trip.',
      templates: {
        create: 'views/tripapply/create.html'
      },
      controllers: _.assign(TripApplyController.controllers, {
        index: [
          '$rootScope',
          '$scope',
          '$state',
          '$controller',
          function($rootScope, $scope, $state, $controller) {
            $scope.query = $scope.query || { sort: '-created' };
            return $controller(TripApplyController.methods.index, {$scope: $scope});
          }
        ],
        create: [
          '$rootScope',
          '$scope',
          '$state',
          function($rootScope, $scope, $state) {
            $scope.submission = {};

            $rootScope.whenReady.then(function() {
              $scope.submission = {
                data: {
                  clinician: $rootScope.user
                }
              };
            });
            $scope.hideComponents = ['clinician', 'status'];
            $rootScope.currentResource = $scope.currentResource;
            $scope.$on('formSubmission', function () {
              $state.go('tripIndex');
            });
            $scope.form = {};
            return {handle: true};
          }
        ]
      })
    };
  }]);

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('TripResource', ['BaseController', function(BaseController) {
    var TripController = BaseController('trip');
    return {
      $get: function() { return null; },
      templates: {
        abstract: 'views/trip/resource.html',
        index: 'views/trip/index.html',
        edit: 'views/trip/edit.html',
        create: 'views/trip/create.html',
        view: 'views/trip/view.html'
      },
      params: {
        create: {
          clientId: null
        }
      },
      controllers: _.assign(TripController.controllers, {
        index: [
          '$rootScope',
          '$scope',
          '$state',
          '$controller',
          function($rootScope, $scope, $state, $controller) {
          $scope.state= 'open';
            $scope.query = $scope.query || { sort: '-created' };
            $rootScope.whenReady.then(function() {
              if ($rootScope.isClinician) {
                $scope.query['data.status__in'] = ['open'];
                $scope.gridColumns = ['status','diagnosis','genderPreferred','from','to'];
                $scope.$broadcast('refreshGrid', $scope.query);
              }
              if ($rootScope.isCustomer) {
                $scope.query['data.status__in'] = ['open','new'];
                $scope.gridColumns = ['status','patientName','relationshiptoCareRecipient','nameofIndividualRequestingQuote','from','to'];
                $scope.$broadcast('refreshGrid', $scope.query);
              }
              if ($rootScope.isClient) {
                $scope.query['data.status__in'] = ['open','new'];
                $scope.gridColumns = ['status','caseId','patientName','nameofIndividualRequestingQuote','from','to'];
                $scope.$broadcast('refreshGrid', $scope.query);
              }

              if ($rootScope.isAdmin) {
                $scope.query['data.status__in'] = ['open','new'];
                $scope.gridColumns = ['status','tripRequestType','patientName','nameofIndividualRequestingQuote','from','to'];
                $scope.$broadcast('refreshGrid', $scope.query);
              }
              $scope.changeStatus =  function (array) {
                $scope.state = array[0];
                $scope.query['data.status__in'] = array;
                $scope.$broadcast('refreshGrid', $scope.query);

              };

            });
            return $controller(TripController.methods.index, {$scope: $scope});
          }
        ],
        create: [
          '$scope',
          '$rootScope',
          '$controller',
          '$stateParams',
          function($scope,$rootScope,$controller) {
            if($rootScope.isClient) {
            $scope.submission = {
              data: {
                clientName:$rootScope.user.data.name,
                status: 'new',
                tripRequestType:'business'
              }
            };
          }
          else {
            $scope.submission = {
              data: {
                status: 'new',
                tripRequestType:'customer'
              }
            };
          }
            $scope.hideComponents = ['status','tripRequestType'];
            return $controller(TripController.methods.create, {$scope: $scope});
          }
        ],
        edit: [
          '$scope',
          '$controller',
          function($scope, $controller) {
            $scope.hideComponents = ['status','tripRequestType'];
            return $controller(TripController.methods.edit, {$scope: $scope});
          }
        ],
        view: [
          '$scope',
          '$state',
          '$controller',
          'Formio',
          'AppConfig',
          '$rootScope',
          '$http',
          '$q',
          function($scope, $state, $controller, Formio, AppConfig,$rootScope,$http,$q) {
            var quoteFormio = new Formio(AppConfig.resources.quote.form);
            $scope.submissions = {};
            $scope.applyLoaded = false;
            $scope.applications =[];
            $scope.quoteSubmission = false;
            $scope.quote = {};

            (new Formio($scope.currentResource.submissionUrl).loadSubmission().then(function (submission) {
               $scope.data = submission;
            }));

            $scope.currentResource.loadSubmissionPromise.then(function(submission) {
              var applyFormio = new Formio(AppConfig.resources.apply.form);
              applyFormio.loadSubmissions({
                params: {
                  'data.trip._id': submission._id,
                  limit: 99999
                }
              }).then(function(result) {
                $scope.applyLoaded = true;
                $scope.applications = result;
              });

              if (submission.data.status !== 'new' && !$scope.quoteSubmission) {
                quoteFormio.loadForm()
                  .then(function(form) {
                    $scope.quoteForm = form;
                  });
                quoteFormio.loadSubmissions({
                  params: {
                    'data.tripId': submission._id
                  }
                })
                  .then(function(submissions) {
                    if (submissions.length) {
                      $scope.quoteSubmission = submissions[0];

                      /**
                       * Job Forms
                       */
                      $scope.patient = {};
                      $scope.formsSrc = AppConfig.appUrl + '/form';
                      $scope.loaded = false;

                      // Load submissions for all forms so they are available.
                      $scope.submissions = {};
                      $scope.currentResource.loadSubmissionPromise.then(function(resource) {
                        var jobFormio = new Formio(AppConfig.forms.jobSubmissions);
                        var jobSubmissionPromise = jobFormio.loadSubmissions({
                          params: {
                            limit: 9999,
                            'data.jobId': submissions[0]._id
                          }
                        });
                        jobSubmissionPromise.then(function(res) {
                          res.forEach(function(jobSubmission) {
                            $scope.submissions[jobSubmission.data.formId] = {_id: jobSubmission.data.submissionId};
                          });
                        });
                        var query = [
                          {
                            $match: {
                              'data.job._id': resource._id
                            }
                          }
                        ];
                        var oldPromise = $http.post(AppConfig.appUrl + '/report', query);
                        oldPromise.then(function(res) {
                          res.data.forEach(function(submission) {
                            if (!$scope.submissions[submission.form]) {
                              $scope.submissions[submission.form] = submission;
                            }
                          });
                        })
                          .catch(function() {
                            // Do nothing. We are probably offline.
                          });

                        $q.all([jobSubmissionPromise, oldPromise])
                          .then(function() {
                            $scope.loaded = true;
                            (new Formio(AppConfig.appUrl + '/patientdemographics')).loadForm().then(function(form) {
                              $scope.patient.form = form;
                              if ($scope.submissions[$scope.patient.form._id]) {
                                (new Formio(AppConfig.appUrl + '/patientdemographics/submission/' + $scope.submissions[$scope.patient.form._id]._id)).loadSubmission().then(function(submission) {
                                  $scope.patient.submission = submission;
                                });
                              }
                            });
                          });
                      });

                     /*job forms
                     * end*/
                    }
                  });
              }
            });

            /**
             * Accept Clinician
             * @param clinician
             */
            $scope.acceptClinician= function(application) {
              var applyFormio = new Formio(AppConfig.resources.apply.form + '/submission/' + application._id);
              application.data.status = 'accepted';
              applyFormio.saveSubmission(application);
            };

            /**
             * Decline Clinician
             * @param clinician
             */
            $scope.declineClinician= function(application) {
              var applyFormio = new Formio(AppConfig.resources.apply.form + '/submission/' + application._id);
              application.data.status = 'declined';
              applyFormio.saveSubmission(application);
            };

            /**
             * Set status
             * @param status
             */
            $scope.setStatus = function(status) {
              $scope.currentResource.submission.data.status = status;
              $scope.currentResource.formio.saveSubmission($scope.currentResource.submission);
              if (status === 'open') {
                var clinicianFormio = new Formio(AppConfig.resources.clinician.form);
                clinicianFormio.loadSubmissions({
                  params: {
                    'data.doNotSendEmails__ne': true,
                    limit: 99999
                  }
                })
                  .then(function(clinicians) {
                    var messageFormio = new Formio(AppConfig.forms.message);
                    messageFormio.saveSubmission({
                      data: {
                        type: 'clinicianNotification',
                        to: clinicians.reduce(function(prev, clinician) {
                          if (prev !== '') {
                            prev += ','
                          }
                          prev += clinician.data.email;
                          return prev;
                        }, ''),
                        data: $scope.currentResource.submission.data
                      }
                    });
                  });
              }
            };



            /**
             * Create Quote
             * @param id
             */
            $scope.createCustomer = function(id) {
              $state.go('quoteCreate', {tripId: $scope.currentResource.submission._id,customer:id});
            };
            $scope.createClient = function(id) {
              $state.go('quoteCreate', {tripId: $scope.currentResource.submission._id,client:id});
            };

            /**
             * Edit Quote
             * @param quote
             */
            $scope.editQuote = function(quote) {
              $state.go('quote.edit', {quoteId: quote._id});
            };

            /**
             * Set Status Quote
             * @param status
             */
            $scope.quoteStatus = function(status) {
              $scope.setStatus(status);
              $scope.quoteSubmission.data.status = (status === 'accepted') ? 'inprogress' : status;
              quoteFormio = new Formio(AppConfig.resources.quote.form + '/submission/' + $scope.quoteSubmission._id);
              quoteFormio.saveSubmission($scope.quoteSubmission);
              if (status === 'rejected') {
                var messageFormio = new Formio(AppConfig.forms.message);
                messageFormio.saveSubmission({
                  data: {
                    type: 'quoteRejection',
                    to: $scope.applications.reduce(function(prev, apply) {
                      if (prev !== '') {
                        prev += ','
                      }
                      prev += apply.data.clinician.data.email;
                      return prev;
                    }, ''),
                    data: $scope.currentResource.submission.data
                  }
                });
              }
              if (status === 'accepted') {
                $state.go('trip.patient', {tripId: $scope.currentResource.submission._id});
              }
            };
          }
        ]
      })
    };
  }]);

/* globals chance, _ */
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('QuoteResource', ['BaseController', function(BaseController) {
    var QuoteController = BaseController('quote');
    var calculate = function(newData, oldData, pricing) {
      // We are assuming the info in submission.data.client is the most recent. We probably want to reload the
      // submission in case they have updated the rates.
      newData.subTotal = 0;
      if (newData.clinician) {
        angular.forEach(newData.clinician, function(clinician) {
          if (newData.client && typeof newData.client.data[clinician.profession] !== 'undefined') {
            clinician.costperDay = newData.client.data[clinician.profession];
          }
          if (clinician.costperDay && clinician.numberofDays) {
            clinician.total = clinician.costperDay * clinician.numberofDays;
            clinician.total = Number((clinician.total).toFixed(2));
            newData.subTotal += clinician.total;
          }
          else {
            clinician.total = 0;
          }
        });
      }
      if (newData.meals) {
        angular.forEach(newData.meals, function(meal) {
          if (meal.dailyRate && meal.days) {
            meal.total2 = meal.dailyRate * meal.days;
            meal.total2 = Number((meal.total2).toFixed(2));
            newData.subTotal += meal.total2;
          }
          else {
            meal.total2 = 0;
          }
        });
      }
      if (newData.medicalEquipment) {
        angular.forEach(newData.medicalEquipment, function(equipment, index) {
          if (equipment.equipment && oldData.medicalEquipment && oldData.medicalEquipment[index] && equipment.equipment != oldData.medicalEquipment[index].equipment) {
            var price = _.find(pricing, {'key' : equipment.equipment});
            if (price) {
              equipment.dailyRate2 = parseInt(price.price);
            }
          }
          if (equipment.dailyRate2 && equipment.days2) {
            equipment.total3 = equipment.dailyRate2 * equipment.days2;
            equipment.total3 = Number((equipment.total3).toFixed(2));
            newData.subTotal += equipment.total3;
          }
          else {
            equipment.total3 = 0;
          }
        });
      }
      if (newData.expenses) {
        angular.forEach(newData.expenses, function(expense, index) {
          if (expense.supplies && oldData.expenses && oldData.expenses[index] && expense.supplies != oldData.expenses[index].supplies) {
            var price = _.find(pricing, {'key' : expense.supplies});
            if (price) {
              expense.cost = parseInt(price.price);
            }
          }
          if (expense.cost && expense.quantityUsed) {
            expense.total4 = expense.cost * expense.quantityUsed;
            expense.total4 = Number((expense.total4).toFixed(2));
            newData.subTotal += expense.total4;
          }
          else {
            expense.total4 = 0;
          }
        });
      }
      if (newData.airTransportation) {
        angular.forEach(newData.airTransportation, function(airTransportation) {
          if (airTransportation.price) {
            if (airTransportation.markup) {
              airTransportation.total9 = Math.round((airTransportation.price * (1 + (airTransportation.markup/100))) * 100) / 100;
            }
            else {
              airTransportation.total9 = airTransportation.price;
            }
            newData.subTotal += airTransportation.total9;
          }
        });
      }
      if (newData.groundTransportation) {
        angular.forEach(newData.groundTransportation, function(groundTransportation) {
          // THIS IS COMMENTED OUT BECAUSE: the google distancematrix api doesn't allow ajax access.
          // See: http://stackoverflow.com/questions/20204991/getjson-stop-working-in-google-maps-api-distancematrix
          // If addresses change, recalculate the distance.
          //if (oldData.groundTransportation &&
          //  (
          //    !angular.equals(groundTransportation.from, oldData.groundTransportation[index].from) ||
          //    !angular.equals(groundTransportation.to, oldData.groundTransportation[index].to)
          //  )
          //) {
          //  if (groundTransportation.from && groundTransportation.to) {
          //    $http({
          //      url: 'https://maps.googleapis.com/maps/api/distancematrix/json',
          //      method: 'GET',
          //      params: {
          //        key: 'API-KEY',
          //        origins: groundTransportation.from.formatted_address,
          //        destinations: groundTransportation.to.formatted_address
          //      }
          //    })
          //      .then(function(response) {
          //      }, function(err) {
          //      });
          //  }
          //}
          if (groundTransportation.rateperhour && groundTransportation.travelTime) {
            groundTransportation.total8 = groundTransportation.rateperhour * groundTransportation.travelTime;
            groundTransportation.total8 = Number((groundTransportation.total8).toFixed(2));
            newData.subTotal += groundTransportation.total8;
          }
          else {
            groundTransportation.total8 = 0;
          }
        });
      }
      if (newData.hotel) {
        angular.forEach(newData.hotel, function(hotel) {
          if (hotel.dailyRate3 && hotel.days4) {
            hotel.total5 = hotel.dailyRate3 * hotel.days4;
            hotel.total5 = Number((hotel.total5).toFixed(2));
            newData.subTotal += hotel.total5;
          }
          else {
            hotel.total5 = 0;
          }
        });
      }
      if (newData.administrativeandMedicalFees) {
        angular.forEach(newData.administrativeandMedicalFees, function(fee, index) {
          if (fee.typeofFee && oldData.administrativeandMedicalFees && oldData.administrativeandMedicalFees[index] && fee.typeofFee != oldData.administrativeandMedicalFees[index].typeofFee) {
            var price = _.find(pricing, {'key' : fee.typeofFee});
            if (price) {
              fee.fee = parseInt(price.price);
            }
          }
          if (fee.fee && fee.days5) {
            fee.total6 = fee.fee * fee.days5;
            fee.total6 = Number((fee.total6).toFixed(2));
            newData.subTotal += fee.total6;
          }
          else {
            fee.total6 = 0;
          }
        });
      }
      if (newData.miscellaneous) {
        angular.forEach(newData.miscellaneous, function(miscellaneous) {
          if (miscellaneous.fee2 && miscellaneous.days3) {
            miscellaneous.total7 = miscellaneous.fee2 * miscellaneous.days3;
            miscellaneous.total7 = Number((miscellaneous.total7).toFixed(2));
            newData.subTotal += miscellaneous.total7;
          }
          else {
            miscellaneous.total7 = 0;
          }
        });
      }
      if (newData.payingbycreditcard) {
        newData.creditcardfee = Math.round(newData.subTotal * 0.04);
      }
      else {
        newData.creditcardfee = 0;
      }
      newData.subTotal = Math.round(newData.subTotal * 100) / 100;
      newData.grandTotal = Math.round((newData.subTotal + newData.creditcardfee) * 100) / 100;
    };
    return {
      $get: function() { return null; },
      templates: {
        index: 'views/quote/index.html',
        abstract: 'views/resource.html',
        view: 'views/quote/view.html',
        edit: 'views/quote/edit.html',
        create: 'views/quote/create.html'
      },
      params:{
        create:{
          client:null,
          customer:null
        }
      },
      query:'?status&tripId&client&customer',
      controllers: _.assign(QuoteController.controllers, {
        index: [
          '$scope',
          '$state',
          '$rootScope',
          '$stateParams',
          '$controller',
          function(
            $scope,
            $state,
            $rootScope,
            $stateParams,
            $controller
          ) {
            if($rootScope.isClinician) {
              $state.go('home');
            }
            $scope.quoteFields = ['quoteId', 'status', 'client', 'companyReferenceNumber'];
            $scope.quoteForm = '';
            $scope.quoteStatus = $stateParams.status || 'open';

            $scope.query = {
              'data.status': $scope.quoteStatus
            };
            $scope.quoteStates = {
              open: 'Open',
              inprogress: 'In Progress',
              closed: 'Close - Invoiced',
              rejected: 'Closed - Rejected'
            };

            var showQuotes = function(state) {
              $scope.$broadcast('reloadGrid', $rootScope.resources.quote.form, {
                'data.status': state
              });
            };

            $scope.filterQuote = function(filter) {
              showQuotes(filter.status);
            };
            showQuotes('open');
            $rootScope.pageTitle = $scope.quoteStates[$scope.quoteStatus] + ' Quotes';
            return $controller(QuoteController.methods.index, {$scope: $scope});
          }
        ],
        create: [
          '$scope',
          '$state',
          '$stateParams',
          '$rootScope',
          'FormioUtils',
          'Formio',
          'AppConfig',
          '$controller',
          function(
            $scope,
            $state,
            $stateParams,
            $rootScope,
            FormioUtils,
            Formio,
            AppConfig,
            $controller
          ) {
            $scope.trip = {};
            if ($stateParams.tripId) {
              $scope.submission.data.submittedClinician = [];
              $scope.submission.data.tripId = $stateParams.tripId;
              var tripFormio = new Formio(AppConfig.resources.trip.form + '/submission/' + $scope.submission.data.tripId);
              tripFormio.loadSubmission()
                .then(function(trip) {
                  if ($stateParams.client) {
                    var clientFormio = new Formio(AppConfig.resources.client.form + '/submission/' + trip.owner);
                    clientFormio.loadSubmission()
                      .then(function(client) {
                        $scope.submission.data.client = client;
                      });
                    $scope.hideComponents = ['customer']
                  }
                  else if($stateParams.customer){
                    var customerFormio = new Formio(AppConfig.resources.customer.form + '/submission/' + trip.owner);
                    customerFormio.loadSubmission()
                      .then(function(customer) {
                        $scope.submission.data.customer = customer;
                      });
                    $scope.hideComponents = ['client']
                  }
                  $scope.submission.data.acceptedClinician = [];
                  $scope.submission.data.rejectedClinicians = [];
                  new Formio(AppConfig.resources.apply.form).
                  loadSubmissions({
                    params:{
                      limit:9999,
                      'data.trip._id':trip._id
                    }
                  }).then(function (clinicians) {
                    _.each(clinicians,function (clinician) {
                      $scope.submission.data.submittedClinician.push(clinician.data.clinician);
                      if(clinician.data.status === 'accepted') {
                        $scope.submission.data.acceptedClinician.push(clinician.data.clinician);
                      }
                      else if (clinician.data.status === 'declined'){
                        $scope.submission.data.rejectedClinicians.push(clinician.data.clinician);
                      }
                    });
                  });
                  $scope.submission.data.location = trip.data.from;
                  $scope.submission.data.destination = trip.data.to;
                  $scope.submission.data.diagnosis = trip.data.diagnosis;
                  $scope.submission.data.nameofPersonRequestingQuote = trip.data.nameofIndividualRequestingQuote;
                  $scope.submission.data.contactNumber = trip.data.telephoneNumber;
                  $scope.submission.data.contactEmail = trip.data.emailAddress;
                  $scope.submission.data.email = trip.data.emailAddress;
                  $scope.submission.data.patientName = trip.data.patientName;
                });

            }
            $scope.submission.data.quoteId = '1' + chance.integer({min: 0, max: 1000000});
            $scope.submission.data.status = 'open';
            $scope.submission.data.administrativeandMedicalFees = [
              {
                typeofFee: "administrativeFee",
                days5: 1,
                fee: 250
              },
              {}
            ];
            $scope.$on('formLoad', function(error, form) {
              var hiddenFields = ['status'];
              if ($stateParams.clientId) {
                hiddenFields.push('client');
              }
              FormioUtils.hideFields(form, hiddenFields);
            });

            $scope.$watch('submission.data.client', function(client) {
              if (!client) { return; }
              $scope.submission.data.clientEmail = client.data.email;
            });
            $scope.$watch('submission.data', function(newData, oldData) {
              calculate(newData, oldData, $rootScope.pricing);
            }, true);
            $scope.$on('formSubmission', function() {
              if ($scope.submission.data.tripId) {
                var messageFormio = new Formio(AppConfig.forms.message);
                messageFormio.saveSubmission({
                  data: {
                    type: 'quoteCreation',
                    to: $scope.submission.data.email,
                    data: angular.copy($scope.submission.data)
                  }
                });
              }
            });
            $scope.$on('cancel',function () {
              localStorage.removeItem('newQuote');
            });

            return $controller(QuoteController.methods.create, {$scope: $scope});
          }
        ],
        edit: [
          '$scope',
          '$state',
          '$stateParams',
          '$rootScope',
          'FormioUtils',
          'Formio',
          'AppConfig',
          '$controller',
          function(
            $scope,
            $state,
            $stateParams,
            $rootScope,
            FormioUtils,
            Formio,
            AppConfig,
            $controller
          ) {
           $scope.$on('formLoad', function(error, form) {
             $scope.trip = {};
             var tripFormio = new Formio(AppConfig.resources.trip.form + '/submission/' + $scope.currentResource.submission.data.tripId);
              if ($scope.currentResource.submission.data.tripId) {
                tripFormio.loadSubmission().then(function(trip) {
                  $scope.trip = trip;

                  $scope.$watch('submission.data.status', function(status) {

                    // Quote "Open" ("open") === TripRequest "Open" ("open")
                    if (status === "open") {
                      $scope.trip.data.status = "open";
                    }

                    // Quote "Closed - Invoiced" ("closed") === TripRequest "Open" ("open")
                    if (status === "closed") {
                      $scope.trip.data.status = "accepted";
                    }

                    // Quote "In Progress" ("inprogress") === TripRequest "Closed - Accepted" ("accepted")
                    if (status === "inprogress") {
                      $scope.trip.data.status = "accepted";
                    }

                    // Quote "Closed - Rejected" ("rejected") === TripRequest "Closed - Rejected" ("rejected")
                    if (status === "rejected") {
                      $scope.trip.data.status = "rejected";
                    }
                  });

                  $scope.$on('formSubmission', function() {
                    if ($scope.currentResource.submission.data.tripId) {
                      tripFormio.saveSubmission($scope.trip).then(function(submission){
                      });
                    }
                  });
                });
              }
            });

            if (!$scope.submission) {
              $scope.submission = { data: {}};
            }
            $scope.$watch('submission.data', function(newData, oldData) {
              calculate(newData, oldData, $rootScope.pricing);
            }, true);

            return $controller(QuoteController.methods.edit, {$scope: $scope});
          }
        ],
        view: [
          '$scope',
          'Formio',
          function(
            $scope,
            Formio
          ) {
            $scope.currentResource.submission = { data: {}};
            (new Formio($scope.currentResource.submissionUrl)).loadSubmission().then(function(quote) {
              $scope.quote = quote;
            });
            $scope.hiddenFields = {
              clinician:true,
              profession: true,
              costperDay: true,
              numberofDays: true,
              meals: true,
              medicalEquipment: true,
              equipment: true,
              expenses: true,
              hotel: true,
              administrativeandMedicalFees: true,
              miscellaneous: true,
              days: true,
              days1: true,
              days2: true,
              days3: true,
              dayss: true,
              days4: true,
              days5: true,
              total: true,
              total1: true,
              total2: true,
              total3: true,
              total4: true,
              total5: true,
              total6: true,
              total7: true,
              total8: true,
              cost: true,
              fee: true,
              fee1: true,
              fee2: true,
              dailyRate: true,
              dailyRate2: true,
              dailyRate3: true,
              ratePerDay: true,
              rateperhour: true,
              travelTime: true,
              typeofFee: true,
              supplies: true,
              datagrid: true,
              class: true,
              arrival: true,
              departure: true,
              airline: true,
              miscellaneousItem: true,
              quantityUsed: true,
              hotelLocation: true,
              creditcardfee: true,
              subTotal: true,
              grandTotal: true
            };
          }
        ]
      })
    };
  }]);

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('JobResource', ['BaseController', function(BaseController) {
    var JobController = BaseController('quote');
    return {
      $get: function() { return null; },
      query: '?quote',
      templates: {
        index: 'views/job/index.html',
        create: 'views/job/create.html',
        abstract: 'views/job/job.html',
        view: 'views/job/view.html'
      },
      controllers: _.assign(JobController.controllers, {
        index: [
          '$scope',
          function(
            $scope
          )
          {
            $scope.quoteId = '';
            $scope.query = {
              sort: '-data.dateofDeparture',
              'data.status__in': 'inprogress,closed'

            };
            $scope.quoteSearch = function() {
              if ($scope.quoteId) {
                $scope.query['data.quoteId__regex'] = $scope.quoteId;
              }
              else {
                delete $scope.query['data.quoteId__regex'];
              }
              $scope.$broadcast('reloadGrid', $scope.resources.quote.form, $scope.query);
            };

            if ($scope.hasAccess('quote', 'read_all')) {
              $scope.columns = [{key: 'quoteId', label: 'Trip Number'},{key:  'acceptedClinician', label: 'Clinician'}, 'client', 'patientName', 'dateofDeparture', 'status'];
            }
            else {
              $scope.columns = [{ key: 'quoteId', label: 'Trip Number'},{key:  'acceptedClinician', label: 'Clinician'}, 'patientName', 'dateofDeparture', 'status'];
            }
          }
        ],
        //create: [
        //  '$scope',
        //  '$stateParams',
        //  '$compile',
        //  '$controller',
        //  'Formio',
        //  'AppConfig',
        //  function(
        //    $scope,
        //    $stateParams,
        //    $compile,
        //    $controller,
        //    Formio,
        //    AppConfig
        //  ) {
        //    if ($stateParams.quote) {
        //      var quoteUrl = AppConfig.resources.quote.form + '/submission/' + $stateParams.quote;
        //      (new Formio(quoteUrl)).loadSubmission().then(function(quote) {
        //        $scope.submission.data.quote = quote;
        //      });
        //    }
        //    $scope.alerts = [];
        //    $scope.$watch('submission.data.quote', function(quote) {
        //      if (quote) {
        //        var jobProvider = new Formio(AppConfig.resources.job.form);
        //        jobProvider.loadSubmissions({
        //            params: {
        //              'data.quote._id': quote._id
        //            }
        //          })
        //          .then(function(jobs) {
        //            if (jobs.length >= 1) {
        //              $scope.existingJobId = jobs[0]._id;
        //            }
        //            else {
        //              $scope.existingJobId = false;
        //            }
        //          });
        //      }
        //    });
        //    return $controller(JobController.methods.create, {$scope: $scope});
        //  }
        //],
        view: [
          '$scope',
          '$controller',
          '$http',
          '$q',
          'Formio',
          'AppConfig',
          function(
            $scope,
            $controller,
            $http,
            $q,
            Formio,
            AppConfig
          ) {
            $scope.patient = {};
            $scope.formsSrc = AppConfig.appUrl + '/form';
            $scope.loaded = false;

            // Load submissions for all forms so they are available.
            $scope.submissions = {};
            $scope.currentResource.loadSubmissionPromise.then(function(resource) {
              var jobFormio = new Formio(AppConfig.forms.jobSubmissions);
              var jobSubmissionPromise = jobFormio.loadSubmissions({
                params: {
                  limit: 200,
                  'data.jobId': resource._id
                }
              });
              jobSubmissionPromise.then(function(res) {
                res.forEach(function(jobSubmission) {
                    $scope.submissions[jobSubmission.data.formId] = {_id: jobSubmission.data.submissionId};
                  });
                });
              var query = [
                {
                  $match: {
                    'data.job._id': resource._id
                  }
                }
              ];
              var oldPromise = $http.post(AppConfig.appUrl + '/report', query);
              oldPromise.then(function(res) {
                  res.data.forEach(function(submission) {
                    if (!$scope.submissions[submission.form]) {
                      $scope.submissions[submission.form] = submission;
                    }
                  });
                })
                .catch(function() {
                  // Do nothing. We are probably offline.
                });

              $q.all([jobSubmissionPromise, oldPromise])
                .then(function() {
                  $scope.loaded = true;
                  (new Formio(AppConfig.appUrl + '/patientdemographics')).loadForm().then(function(form) {
                    $scope.patient.form = form;
                    if ($scope.submissions[$scope.patient.form._id]) {
                      (new Formio(AppConfig.appUrl + '/patientdemographics/submission/' + $scope.submissions[$scope.patient.form._id]._id)).loadSubmission().then(function(submission) {
                        $scope.patient.submission = submission;
                      });
                    }
                  });
                });
            });

            return $controller(JobController.methods.view, {$scope: $scope});
          }
        ]
      })
    };
  }]);

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('CustomerResource', ['BaseController', function(BaseController) {
    var CustomerController = BaseController('customer');
    return {
      $get: function() { return null; },
      templates: {
        abstract: 'views/customer/resource.html',
        edit: 'views/customer/edit.html'
      },
      controllers: _.assign(CustomerController.controllers, {
        edit: ['$scope', '$state', function($scope, $state) {
          $scope.$on('formSubmission', function() {
            $state.go('home');
          });
          return {
            handle: true
          };
        }]
      })
    };
  }]);

/*global _*/
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('ClinicianResource', ['BaseController', function(BaseController) {
    var ClinicianController = BaseController('clinician');
    return {
      $get: function() { return null; },
      templates: {
        index: 'views/clinician/index.html',
        abstract: 'views/clinician/resource.html',
        view: 'views/clinician/view.html'
      },
      controllers: _.assign(ClinicianController.controllers, {
        index: ['$scope', '$controller', function($scope, $controller) {
          $scope.gridColumns = ['fullName', 'status', 'profession', 'email'];

          $scope.clinicianName = '';
          $scope.query = {};

          $scope.clinicianSearch = function() {
            if ($scope.clinicianName) {
              $scope.query['data.fullName__regex'] = $scope.clinicianName;
            }
            else {
              delete $scope.query['data.fullName__regex'];
            }
            $scope.$broadcast('reloadGrid', $scope.resources.clinician.form, $scope.query);
          };

          return $controller(ClinicianController.methods.index, {$scope: $scope});
        }],
        view: ['$scope', '$http', 'Formio', 'AppConfig', function($scope, $http, Formio, AppConfig) {
          $scope.formsSrc = AppConfig.appUrl + '/form';
          $scope.hiddenFields = [ 'picture'];
          $scope.currentResource.loadSubmissionPromise.then(function(submission) {
            if (submission.data.picture && submission.data.picture.length) {
              angular.element('#profile-image').attr({src: 'assets/images/loader.gif'});
              var formio = new Formio($scope.currentResource.formUrl);
              formio.downloadFile(submission.data.picture[0]).then(function(file) {
                angular.element('#profile-image').attr({
                  src: file.url
                });
              });
            }
          });
          // Load submissions for all forms so they are available.
          $scope.submissions = {};
          $scope.currentResource.loadSubmissionPromise.then(function(resource) {
            var query = [
              {
                $match: {
                  'data.clinician._id': resource._id
                }
              }
            ];
            $http.post(AppConfig.appUrl + '/report', query).then(function(res) {
              res.data.forEach(function(submission) {
                $scope.submissions[submission.form] = submission;
              });
            });
          });
        }]
      })
    };
  }]);

/* globals _ */
angular.module('skynurses')
  .provider('ClientResource', ['BaseController', function(BaseController) {
    var ClientController = BaseController('client');
    return {
      $get: function() { return null; },
      templates: {
        index: 'views/client/index.html',
        abstract: 'views/resource.html',
        view: 'views/client/view.html',
        create: 'views/client/create.html'
      },
      controllers: _.assign(ClientController.controllers, {
        index: ['$scope', '$state', '$controller', function($scope, $state, $controller) {
          $scope.clientColumns = [
            'name',
            'address',
            'phoneNumber',
            'websiteAddress'
          ];
          return $controller(ClientController.methods.index, {$scope: $scope});
        }],
        view: [
          '$scope',
          '$rootScope',
          '$compile',
          '$state',
          'FormioUtils',
          '$controller',
          function(
            $scope,
            $rootScope,
            $compile,
            $state,
            FormioUtils,
            $controller
          ) {
            $scope.position = {lat: '40.74', lng: '-74.18'};
            $scope.components = [];
            $scope.contactsComp = {};
            $scope.additionalComp = {};

            var tableComponent = function(component) {
              if (!component.tableView) {
                return false;
              }
              switch (component.key) {
                case 'contacts':
                  $scope.contactsComp = component;
                  return false;
                case 'additionalNotes':
                  $scope.additionalComp = component;
                  return false;
                case 'name':
                case 'address':
                  return false;
                default:
                  return true;
              }
            };

            $scope.currentResource.loadFormPromise.then(function() {
              FormioUtils.eachComponent($scope.currentResource.form.components, function(component) {
                if (tableComponent(component)) {
                  $scope.components.push(component);
                }
              });
            });
            $scope.currentResource.loadSubmissionPromise.then(function() {
              if (!$scope.currentResource.resource) { return; }
              if ($scope.currentResource.resource.data.address) {
                $scope.position.lat = $scope.currentResource.resource.data.address.geometry.location.lat;
                $scope.position.lng = $scope.currentResource.resource.data.address.geometry.location.lng;
              }

              $scope.gridQuery = {'data.client._id': $scope.currentResource.resource._id};
              $scope.gridColumns = ['quoteId', 'status', 'companyReferenceNumber'];
              var gridElement = angular.element(document.createElement('formio-grid'));
              angular.element('#quote-grid').html($compile(gridElement.attr({
                src: "'" + $rootScope.resources.quote.form + "'",
                query: 'gridQuery',
                columns: 'gridColumns'
              }))($scope));

              // When they click on a view go to the quote.
              $scope.$on('rowView', function(error, entity) {
                $state.go('quote.view', {quoteId: entity._id});
              });
            });

            return $controller(ClientController.methods.view, {$scope: $scope});
          }
        ]
      })
    };
  }]);

/* globals _ */
/**
 *  The following shows an example resource provider.
 *
 *  This allows you to hook into the CRUD operations for a single Form.io
 *  resource as it is being indexed, viewed, deleted, created, updated, etc. By
 *  providing your logic here, you can control how Form.io behaves within your
 *  application.
 */
angular.module('skynurses')
  .provider('BoardResource', ['BaseController', function(BaseController) {
    var BoardController = BaseController('missionBoard');
    return {
      $get: function() { return null; },
      templates: {
        index: 'views/board/index.html',
        abstract: 'views/resource.html',
        view: 'views/board/view.html',
        edit: 'views/board/edit.html',
        create: 'views/board/create.html'
      },
      query: '?status',
      controllers: _.assign(BoardController.controllers, {
        index: [
          '$scope',
          '$stateParams',
          '$rootScope',
          '$controller',
          function(
            $scope,
            $stateParams,
            $rootScope,
            $controller
          ) {
            $scope.currentResource.columns = ['patient', 'clinician', { key: 'dateofDepartureforClinician', label: 'Clinician Departure' }, { key: 'dateofDepartureforPatient', label: 'Patient Departure' }, {key: 'statusofMission', label: 'Status'}];
            $scope.query = {
              'data.statusofMission__in': 'In-Progress,inProgress'
            };

            $rootScope.pageTitle = 'Mission Boards';
            return $controller(BoardController.methods.index, {$scope: $scope});
          }
        ],
        create: [
          '$scope',
          '$controller',
          function(
            $scope,
            $controller
          ) {
            return $controller(BoardController.methods.create, {$scope: $scope});
          }
        ],
        edit: [
          '$scope',
          '$controller',
          function(
            $scope,
            $controller
          ) {
            return $controller(BoardController.methods.edit, {$scope: $scope});
          }
        ],
        view: [
          '$scope',
          '$controller',
          function(
            $scope,
            $controller
          ) {
            return $controller(BoardController.methods.view, {$scope: $scope});
          }
        ]
      })
    };
  }]);

angular.module('skynurses')
  .constant('BaseController', function(resource) {
    var BaseController = {
      methods: {
        index: ['$scope', '$state', function ($scope, $state) {
          $scope.$on('rowView', function (event, row) {
            var params = {};
            params[resource + 'Id'] = row._id;
            $state.go(resource + '.view', params);
          });
        }],
        abstract: ['$rootScope', '$scope', function ($rootScope, $scope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        create: ['$rootScope', '$scope', '$state', function ($rootScope, $scope, $state) {
          $rootScope.currentResource = $scope.currentResource;
          $scope.$on('formSubmission', function () {
            $state.go(resource + 'Index');
          });
          $scope.form = {};
          return {handle: true};
        }],
        view: ['$rootScope', '$scope', function ($rootScope, $scope) {
          $rootScope.filePath = $scope.currentResource.formUrl;
          $rootScope.currentResource = $scope.currentResource;
        }],
        edit: ['$rootScope', '$scope', function ($rootScope, $scope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        delete: ['$rootScope', '$scope', function ($rootScope, $scope) {
          $rootScope.currentResource = $scope.currentResource;
        }]
      }
    };

    BaseController.controllers = {
      index: BaseController.methods.index,
      abstract: BaseController.methods.abstract,
      create: BaseController.methods.create,
      view: BaseController.methods.view,
      edit: BaseController.methods.edit,
      delete: BaseController.methods.delete
    };

    return BaseController;
  });

angular.module('skynurses')
  .directive('skyForms', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        src: '=',
        base: '=',
        submissions: '=',
        access: '=?',
        tag: '=?'
      },
      templateUrl: 'views/forms/index.html',
      controller: ['$scope', '$rootScope', 'Formio', function ($scope, $rootScope, Formio) {
        $scope.hasAccess = $rootScope.hasAccess;
        $scope.accessReady = false;
        $rootScope.accessPromise.then(function() {
          $scope.accessReady = true;
        });
        $scope.forms = [];
        var params = {
          type: 'form',
          limit: 9999999
        };
        if ($scope.tag) {
          params.tags = $scope.tag;
        }
        (new Formio($scope.src)).loadForms({params: params}).then(function (forms) {
          $scope.forms = forms;
        });
      }]
    };
  });

angular.module('skynurses')
  .directive('quotePrice', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        quote: '='
      },
      templateUrl: 'views/quote/price.html',
      controller: ['$scope', '$rootScope', 'Formio', function ($scope) {
        $scope.totals = {
          clinicians: 0,
          meals: 0,
          medicalEquipment: 0,
          expenses: 0,
          air: 0,
          ground: 0,
          hotel: 0,
          fees: 0,
          misc: 0,
          subTotal: 0,
          creditCard: 0,
          grandTotal: 0
        };
        $scope.$watch('quote', function(quote) {
          if (!quote) {
            return;
          }
          angular.forEach(quote.data.clinician, function(clinician) {
            $scope.totals.clinicians += clinician.total;
            $scope.totals.subTotal += clinician.total;
          });
          angular.forEach(quote.data.meals, function(meal) {
            $scope.totals.meals += meal.total2;
            $scope.totals.subTotal += meal.total2;
          });
          angular.forEach(quote.data.medicalEquipment, function(medicalEquipment) {
            $scope.totals.medicalEquipment += medicalEquipment.total3;
            $scope.totals.subTotal += medicalEquipment.total3;
          });
          angular.forEach(quote.data.expenses, function(expense) {
            $scope.totals.expenses += expense.total4;
            $scope.totals.subTotal += expense.total4;
          });
          angular.forEach(quote.data.airTransportation, function(airTransportation) {
            if(airTransportation.total9) {
              $scope.totals.air += airTransportation.total9;
              $scope.totals.subTotal += airTransportation.total9;
            }
          });
          angular.forEach(quote.data.groundTransportation, function(groundTransportation) {
            $scope.totals.ground += groundTransportation.total8;
            $scope.totals.subTotal += groundTransportation.total8;
          });
          angular.forEach(quote.data.hotel, function(hotel) {
            $scope.totals.hotel += hotel.total5;
            $scope.totals.subTotal += hotel.total5;
          });
          angular.forEach(quote.data.administrativeandMedicalFees, function(fees) {
            $scope.totals.fees += fees.total6;
            $scope.totals.subTotal += fees.total6;
          });
          angular.forEach(quote.data.miscellaneous, function(misc) {
            $scope.totals.misc += misc.total7;
            $scope.totals.subTotal += misc.total7;
          });
          $scope.totals.creditCard = quote.data.creditcardfee || 0;
          $scope.totals.grandTotal = $scope.totals.subTotal + $scope.totals.creditCard;
        });
      }]
    };
  });

/*globals _*/
(function() {
  'use strict';

  angular
    .module('skynurses')
    .run(runBlock);

  /** @ngInject */
  function runBlock(
    $rootScope,
    Formio,
    FormioAuth,
    FormioOffline,
    AppConfig
  ) {

    FormioOffline.init();

    // Add the sections to the rootScope.
    $rootScope.resources = AppConfig.resources;
    $rootScope.sections = AppConfig.sections;
    $rootScope.pageTitle = '';
    var sections = _.keyBy(AppConfig.sections, function(o) {
      return o.name + 'Index';
    });
    $rootScope.appVersion = AppConfig.appVersion;
    $rootScope.appUrl = AppConfig.appUrl;

    $rootScope.$on('user', function(event, user) {
      if (user.user) {
        $rootScope.whenReady.then(function() {
          if (!$rootScope.isCustomer) {
            (new Formio(AppConfig.forms.settingsForm)).loadSubmissions().then(function(submissions) {
              $rootScope.settings = submissions[0];
              $rootScope.links = $rootScope.settings.data.links;
              $rootScope.pricing = $rootScope.settings.data.pricing;
            });
          }
        })
      }
    });

    // Initialize authentication.
    FormioAuth.init();

    // Add the forms to the root scope.
    angular.forEach(AppConfig.forms, function(url, form) {
      $rootScope[form] = url;
    });
    // Trigger when a logout occurs.

    $rootScope.settings = {};
    $rootScope.links = [];

    // Reset the current resource for every state change.
    $rootScope.$on('$stateChangeStart', function() {
      $rootScope.currentResource = null;
    });

    $rootScope.$on('$stateChangeSuccess', function(event, toState) {
      if (sections.hasOwnProperty(toState.name)) {
        $rootScope.pageTitle = sections[toState.name].title;
      }
      else {
        $rootScope.pageTitle = '';
      }
    });

    $rootScope.$on('formio.unauthorized', function() {
    });

    // This forces the jobSubmission form to be loaded. Otherwise it is never visited.
    (new Formio(AppConfig.forms.jobSubmissions)).loadForm();
    (new Formio(AppConfig.forms.message)).loadForm();

    $rootScope.$on('formio.offline.formSubmission', function(event, key, submission, queuedRequest) {
      // If a form submission was using a temporary id, we need to update the submission id in subsequent requests.
      if (queuedRequest.request.method === 'POST' && submission._id !== queuedRequest.request.data._id) {
        var oldId = queuedRequest.request.data._id;
        $rootScope.offline.submissionQueue.forEach(function(nextRequest) {
          if (nextRequest.request.data.data.submissionId === oldId && nextRequest.request.method === 'POST') {
            nextRequest.request.data.data.submissionId = submission._id;
            $rootScope.offline._saveQueue();
          }
        });
      }
    });
  }
})();

/* globals moment */
(function() {
  'use strict';

  angular
    .module('skynurses')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig(
    $stateProvider,
    $injector,
    $urlRouterProvider,
    FormioResourceProvider,
    FormioFormsProvider,
    FormioOfflineProvider,
    AppConfig
  ) {

    FormioOfflineProvider.register({
      errorUrl: '/error',
      homeState: 'home'
    });

    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'views/home/index.html',
        controller: ['$rootScope', '$scope', '$state', '$http', 'AppConfig', 'Formio','$compile', function($rootScope, $scope, $state, $http, AppConfig, Formio,$compile) {
          if ($rootScope.user) {
            $rootScope.pageTitle = 'Welcome ' + $rootScope.user.data.fullName;
          }
          $scope.gridColumns = ['quoteId', 'status', 'client', 'companyReferenceNumber'];
          $scope.tripCustomerColumns = ['status','tripRequestType','patientName','nameofIndividualRequestingQuote','from','to'];
          $scope.tripBusinessColumns = ['status','tripRequestType','clientName','patientName','nameofIndividualRequestingQuote','from','to'];
          $scope.$on('rowView', function(error, entity) {
            $state.go('quote.view', {quoteId: entity._id});
          });
          $scope.$on('tripView', function(error, entity) {
            $state.go('trip.view', {tripId: entity._id});
          });
          $scope.date = {
            date: '',
            time: ''
          };
          (function checkTime() {
            $scope.date.date = moment().format('MMMM Do YYYY');
            $scope.date.time = moment().format('h:mm:ss a');
            if(!$scope.$$phase) {
              $scope.$apply();
            }
            setTimeout(checkTime, 1000);
          })();

          var formUrl = AppConfig.resources.clinician.form;
          $scope.clinicianForm = {
            url: formUrl,
            form: {}
          };
          $scope.clinicianForm.formio = (new Formio(formUrl));
          $scope.clinicianForm.promise = $scope.clinicianForm.formio.loadForm().then(function (form) {
            $scope.clinicianForm.form = form;
          });

          $scope.userPromise.then(function() {
            if ($scope.user && $scope.user.data.picture && $scope.user.data.picture.length) {
              angular.element('#profile-image').attr({src: 'assets/images/loader.gif'});
              var formio = new Formio(formUrl);
              formio.downloadFile($scope.user.data.picture[0]).then(function(file) {
                angular.element('#profile-image').attr({
                  src: file.url
                });
              });
            }
          });

          // Load submissions for all forms so they are available.
          $scope.formsSrc = AppConfig.appUrl + '/form';
          $scope.submissions = {};
          $scope.userPromise.then(function(resource) {
            if (!resource) {
              return;
            }
            var query = [
              {
                $match: {
                  'data.clinician._id': resource._id
                }
              }
            ];
            $http.post(AppConfig.appUrl + '/report', query).then(function(res) {
              res.data.forEach(function(submission) {
                $scope.submissions[submission.form] = submission;
              });
            });
          });

          $scope.contactsComp = [];
          $scope.additionalComp = [];
          $scope.position = {lat: '40.74', lng: '-74.18'};
          $scope.clientForm = {
            url: formUrl,
            form: {}
          };
          $rootScope.whenReady.then(function() {
            if ($rootScope.isClient) {

              $scope.clientForm.formio = (new Formio(AppConfig.resources.client.form));
              $scope.clientForm.promise = $scope.clientForm.formio.loadForm().then(function (form) {
                $scope.clientForm.form = form;
              });
              $rootScope.pageTitle = 'Welcome ' + $rootScope.user.data.primaryFirstName + ' ' + $rootScope.user.data.primaryLastName;
              $scope.position.lat = $rootScope.user.data.address.geometry.location.lat;
              $scope.position.lng = $rootScope.user.data.address.geometry.location.lng;
              $scope.gridQuery = {'data.client._id': $rootScope.user._id};
              $scope.gridColumns = ['quoteId', 'status', 'companyReferenceNumber'];
              var gridElement = angular.element(document.createElement('formio-grid'));
              angular.element('#quote-grid').html($compile(gridElement.attr({
                src: "'" + $rootScope.resources.quote.form + "'",
                query: 'gridQuery',
                columns: 'gridColumns'
              }))($scope));
            }
          });
        }]
      })
      .state('auth', {
        url: '/auth',
        abstract:true,
        templateUrl: 'views/user/auth.html'
      })
      .state('auth.portal', {
        url: '/portal',
        templateUrl: 'views/user/portal.html',   controller: ['$scope', '$state', '$rootScope', function ($scope, $state, $rootScope) {
          if ($rootScope.isAuthenticated) {
            $state.go('home');
          }
          else {
            /* eslint-disable angular/window-service */
            window.localStorage.clear();
          }
        }]
      })
      .state('auth.customer', {
        url: '/customer',
        templateUrl: 'views/user/customer.html',
        controller: ['$scope', '$state', '$rootScope', function ($scope, $state, $rootScope) {
            $scope.$on('formSubmission', function (err, submission) {
              if (!submission) {
                return;
              }
              $rootScope.setUser(submission);
              $state.go('home');
            });
        }]
      })
      .state('auth.business', {
        url: '/business',
        templateUrl: 'views/user/business.html',
        controller: ['$scope', '$state', '$rootScope', function ($scope, $state, $rootScope) {
          $scope.$on('formSubmission', function (err, submission) {
            if (!submission) {
              return;
            }
            $rootScope.setUser(submission, 'user');
            $state.go('home');
          });
        }]
      })

      .state('profile', {
        url: '/profile',
        templateUrl: 'views/user/profile.html',
        controller: ['$scope', '$state', '$rootScope', 'AppConfig', function ($scope, $state, $rootScope, AppConfig) {
          $rootScope.whenReady.then(function() {
            if ($rootScope.isClinician) {
              $scope.profileUrl = AppConfig.resources.clinician.form + '/submission/' + $rootScope.user._id;
              $scope.hideComponents = [
                'status',
                'statusDate',
                'profession',
                'professionalLicense',
                'dateofExpiration',
                'locationofLicensure',
                'policyProvider',
                'professionalLiabilityPolicyNumber',
                'policyExpirationDate',
                'perOccurrence',
                'annualAggregate',
                'repatriationInsurance',
                'expirationDate'
              ];
            }
            else {
              $scope.profileUrl = AppConfig.resources.customer.form + '/submission/' + $rootScope.user._id;
            }
          });
          $scope.$on('formSubmission', function (err, submission) {
            if (!submission) {
              return;
            }
            $rootScope.setUser(submission);
            $state.go('home');
          });
        }]
      })
      .state('trip.patient', {
        url: '/patient',
        parent: 'trip',
        templateUrl: 'views/trip/patient.html',
        controller: [
          '$scope',
          '$state',
          'Formio',
          'AppConfig',
          function($scope, $state, Formio, AppConfig) {
            $scope.hideComponents = ['job'];
            $scope.submission = {data: {}};
            $scope.$parent.currentResource.loadSubmissionPromise
              .then(function(trip) {
                var quoteFormio = new Formio(AppConfig.resources.quote.form);
                quoteFormio.loadSubmissions({
                    params: {
                      'data.tripId': trip._id
                    }
                  })
                  .then(function(submissions) {
                    if (submissions.length) {
                      $scope.quoteSubmission = submissions[0];
                      var patientFormio = new Formio(AppConfig.forms.patientDemographics);
                      patientFormio.loadSubmissions({
                          params: {
                            'data.job._id': $scope.quoteSubmission._id
                          }
                        })
                        .then(function(submissions) {
                          if (submissions.length) {
                            $scope.formUrl = AppConfig.forms.patientDemographics + '/submission/' + submissions[0]._id;
                          }
                          else {
                            $scope.formUrl = AppConfig.forms.patientDemographics;
                            $scope.submission.data.job = $scope.quoteSubmission;
                          }
                        });
                    }
                  });
              });
            $scope.$on('formSubmission', function(err, submission) {
              (new Formio(AppConfig.forms.jobSubmissions)).saveSubmission({
                data: {
                  jobId: $scope.quoteSubmission._id,
                  formId: submission.form,
                  submissionId: submission._id
                }
              }).then(function () {
                $state.go('tripIndex');
              });
            });
          }
        ]
      })
      .state('settings', {
        url: '/settings',
        templateUrl: 'views/settings.html',
        controller: [
          '$scope',
          '$rootScope',
          function(
            $scope,
            $rootScope
          ) {
            $scope.settingsUrl = '';
            $scope.submission = {
              data: {}
            };
            $rootScope.$watch('settings', function(settings) {
              if (!settings || !Object.keys(settings).length) {
                return;
              }
              $scope.settingsUrl = AppConfig.forms.settingsForm + '/submission/' + settings._id;
            });
            $scope.$watch('submission.data.pricing', function(newValue, oldValue) {
              if (oldValue) {
                angular.forEach(newValue, function(row, index) {
                  if (!oldValue[index] || row.name != oldValue[index].name) {
                    /*eslint-disable */
                    row.key = _.camelCase(row.name);
                    /*eslint-enable */
                  }
                });
              }
            }, true);

            $scope.$on('formSubmission',function (event, submission) {
              $rootScope.links = submission.data.links
            });
          }
        ]
      })
      .state('travelIndex', {
        url: '/travel',
        templateUrl: 'views/travel/index.html'
      })
      .state('missionIndex', {
        url: '/mission',
        templateUrl: 'views/mission/index.html'
      })
      .state('print', {
        url: '/print/{formId}/{submissionId}',
        templateUrl: 'views/print.html',
        controller: function($scope, $stateParams, Formio, AppConfig) {
          $scope.loaded = false;

          // Load the form.
          var formUrl = AppConfig.appUrl + '/form/' + $stateParams.formId;
          $scope.currentForm = {
            name: name,
            url: formUrl,
            form: {}
          };

          $scope.currentForm.formio = (new Formio(formUrl));
          $scope.currentForm.promise = $scope.currentForm.formio.loadForm().then(function (form) {
            $scope.currentForm.form = form;
          });

          // Load the submission.
          $scope.currentSubmission = {
            url: $scope.currentForm.url + '/submission/' + $stateParams.submissionId,
            submission: {
              data: {}
            }
          };

          // Store the formio object.
          $scope.currentSubmission.formio = (new Formio($scope.currentSubmission.url));

          // Load the current submission.
          $scope.currentSubmission.promise = $scope.currentSubmission.formio.loadSubmission().then(function (submission) {
            $scope.loaded = true;
            $scope.currentSubmission.submission = submission;
            return submission;
          });
        }
      });

    // Register all of the resources.
    angular.forEach(AppConfig.resources, function(resource, name) {
      FormioResourceProvider.register(name, resource.form, $injector.get(resource.resource + 'Provider'));
    });

    var calculateExpense = function(newData) {
      newData.subTotal =  parseFloat((0).toFixed(2));
      if (newData.meals) {
        angular.forEach(newData.meals, function(item) {
          if (item.amountincludingGratuityinUsd || item.cardTransactionFee) {
            item.totalinUsd = (item.amountincludingGratuityinUsd || 0 ) + (item.cardTransactionFee || 0);
            item.totalinUsd = parseFloat(item.totalinUsd.toFixed(2));
            newData.subTotal += parseFloat(item.totalinUsd.toFixed(2));
          }
          else {
            item.totalinUsd =  parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.transportation) {
        angular.forEach(newData.transportation, function(item) {
          if (item.amountincludingGratuityinUsd2 || item.cardTransactionFee2) {
            item.totalinUsd2 = (item.amountincludingGratuityinUsd2 || 0) + (item.cardTransactionFee2 || 0);
            item.totalinUsd2 = parseFloat(item.totalinUsd2.toFixed(2));
            newData.subTotal += parseFloat(item.totalinUsd2.toFixed(2));
          }
          else {
            item.totalinUsd2 =  parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.mileage) {
        angular.forEach(newData.mileage, function(item) {
          if (item.toAirport || item.fromAirport && item.perMile) {
            item.totalAmount2 = ((item.toAirport || 0) + (item.fromAirport || 0)) * (item.perMile || 0);
            item.totalAmount2 = parseFloat(item.totalAmount2.toFixed(2));
            newData.subTotal += parseFloat(item.totalAmount2.toFixed(2));
          }
          else {
            item.totalAmount2 = parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.hotel) {
        angular.forEach(newData.hotel, function(item) {
          if (item.amountinUsd2 || item.cardTransactionFee3) {
            item.totalinUsd3 = (item.amountinUsd2 || 0) + (item.cardTransactionFee3 || 0);
            item.totalinUsd3 = parseFloat(item.totalinUsd3.toFixed(2));
            newData.subTotal += parseFloat(item.totalinUsd3.toFixed(2));
          }
          else {
            item.totalinUsd3 = parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.communication) {
        angular.forEach(newData.communication, function(item) {
          if (item.ofTextMinutes && item.perMinutePerTextCost) {
            item.total = (item.ofTextMinutes || 0) * (item.perMinutePerTextCost || 0);
            item.total = parseFloat(item.total.toFixed(2));
            newData.subTotal += parseFloat(item.total.toFixed(2));
          }
          else {
            item.total = parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.contractor) {
        angular.forEach(newData.contractor, function(item) {
          if (item.daysonAssignment && item.independentContractorAmountPerDay) {
            item.totalAmount = (item.daysonAssignment || 0) * (item.independentContractorAmountPerDay || 0);
            item.totalAmount = parseFloat(item.totalAmount.toFixed(2));
            newData.subTotal += parseFloat(item.totalAmount.toFixed(2));
          }
          else {
            item.totalAmount =  parseFloat((0).toFixed(2));
          }
        });
      }
      if (newData.miscellaneous) {
        angular.forEach(newData.miscellaneous, function(item) {
          if (item.amountinUsd || item.cardTransactionFee4) {
            item.totalinUsd4 = (item.amountinUsd || 0) + (item.cardTransactionFee4 || 0);
            item.totalinUsd4 = parseFloat(item.totalinUsd4.toFixed(2));
            newData.subTotal += parseFloat(item.totalinUsd4.toFixed(2));
          }
          else {
            item.totalinUsd4 =  parseFloat((0).toFixed(2));
          }
        });
      }
      newData.subTotal = parseFloat(newData.subTotal.toFixed(2));
      newData.deductions = parseFloat(newData.deductions.toFixed(2));
      newData.totalReimbursement = parseFloat((newData.subTotal - newData.deductions).toFixed(2));
    }

    var addCalculateExpense = function($scope, key) {
      $scope.$watch(key, calculateExpense, true);
    };
    var calculate = function(newData) {
      var sum = 0;
      if (newData.service) {
        angular.forEach(newData.service, function(service) {
          if (service.amount1) {
            service.amount = sum + service.amount1;
            sum = service.amount
          }
        });
      }
       newData.totalAmountDue1 = sum;
    };

    // Register the forms provider for the jobs.
    FormioFormsProvider.register('job', AppConfig.appUrl, {
      tag: 'job',
      field: 'job',
      templates: {
        form: 'views/forms/form.html',
        submission: 'views/job/submission.html',
        view: 'views/forms/create.html',
        submissionEdit: 'views/forms/edit.html',
        submissionView: 'views/forms/view.html'
      },
      controllers: {
        index: ['$scope', '$rootScope', function($scope, $rootScope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        view: ['$scope', '$state', 'Formio',  'AppConfig', function($scope, $state, Formio, AppConfig) {
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'invoice') {
              $scope.$watch('submission.data', function(newData, oldData) {
                calculate(newData, oldData);
              }, true);
            }
          });

          // Override the default formSubmission event.
          $scope.$on('formSubmission', function (err, submission) {
            (new Formio(AppConfig.forms.jobSubmissions)).saveSubmission({
              data: {
                jobId: $scope.currentResource.submission._id,
                formId: submission.form,
                submissionId: submission._id
              }
            }).then(function() {
              $state.go('job.view', {jobId: $scope.currentResource.submission._id});
            });
          });
          return {
            handle: true
          };
        }],
        submissionView: [
          '$scope',
          function(
            $scope
          ) {
            $scope.loadSubmission = false;
            $scope.currentSubmission.promise.then(function() {
              $scope.loadSubmission = true;
            });
          }
        ],
        submissionEdit: ['$scope', '$state', function($scope, $state) {
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense') {
              addCalculateExpense($scope, 'currentSubmission.submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          // Override the default formSubmission event.
          $scope.hideComponents = ['job'];
          $scope.$on('formSubmission', function () {
            $state.go('job.view', {jobId: $scope.currentResource.submission._id});
          });
          return {
            handle: true
          };
        }]
      }
    });




    FormioFormsProvider.register('trip', AppConfig.appUrl, {
      tag: 'job',
      field: 'trip',
      templates: {
        form: 'views/forms/form.html',
        submission: 'views/job/submission.html',
        view: 'views/forms/create.html',
        submissionEdit: 'views/forms/edit.html',
        submissionView: 'views/forms/view.html'
      },
      controllers: {
        index: ['$scope', '$rootScope', function($scope, $rootScope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        view: ['$scope', '$state', 'Formio',  'AppConfig', function($scope, $state, Formio, AppConfig) {
          $scope.$watch('currentForm.form.name', function(formName) {
            console.log(formName);
            if (formName === 'travelExpense') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'invoice') {
              $scope.$watch('submission.data', function(newData, oldData) {
                calculate(newData, oldData);
              }, true);
            }
          });

          // Override the default formSubmission event.
          $scope.$on('formSubmission', function (err, submission) {
            (new Formio(AppConfig.forms.jobSubmissions)).saveSubmission({
              data: {
                jobId: $scope.currentResource.submission._id,
                formId: submission.form,
                submissionId: submission._id
              }
            }).then(function() {
              $state.go('job.view', {jobId: $scope.currentResource.submission._id});
            });
          });
          return {
            handle: true
          };
        }],
        submissionView: [
          '$scope',
          function(
            $scope
          ) {
            $scope.loadSubmission = false;
            $scope.currentSubmission.promise.then(function() {
              $scope.loadSubmission = true;
            });
          }
        ],
        submissionEdit: ['$scope', '$state', function($scope, $state) {
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense') {
              addCalculateExpense($scope, 'currentSubmission.submission.data');
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
          // Override the default formSubmission event.
          $scope.hideComponents = ['job'];
          $scope.$on('formSubmission', function () {
            $state.go('job.view', {jobId: $scope.currentResource.submission._id});
          });
          return {
            handle: true
          };
        }]
      }
    });

    // Register the forms provider for clinicians.
    FormioFormsProvider.register('clinician', AppConfig.appUrl, {
      tag: 'clinician',
      field: 'clinician',
      templates: {
        form: 'views/forms/form.html',
        submission: 'views/forms/ui-view.html',
        view: 'views/forms/create.html',
        submissionEdit: 'views/forms/edit.html',
        submissionView: 'views/forms/view.html'
      },
      controllers: {
        index: ['$scope', '$rootScope', function($scope, $rootScope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        view: ['$scope', '$state', function($scope, $state) {
          // Override the default formSubmission event.
          $scope.$on('formSubmission', function () {
            $state.go('clinician.view', {clinicianId: $scope.currentResource.submission._id});
          });
          return {
            handle: true
          };
        }],
        submissionView: [
          '$scope',
          function(
            $scope
          ) {
            $scope.loadSubmission = false;
            $scope.currentSubmission.promise.then(function() {
              $scope.loadSubmission = true;
            });
          }
        ],
        submissionEdit: ['$scope', '$state', function($scope, $state) {
          // Override the default formSubmission event.
          $scope.hideComponents = ['clinician'];
          $scope.$on('formSubmission', function () {
            $state.go('clinician.view', {clinicianId: $scope.currentResource.submission._id});
          });
          return {
            handle: true
          };
        }]
      }
    });

    // Register the forms provider for the jobs.
    FormioFormsProvider.register(null, AppConfig.appUrl, {
      tag: 'common',
      templates: {
        view: 'views/forms/create.html'
      },
      controllers: {
        index: ['$scope', '$rootScope', function($scope, $rootScope) {
          $rootScope.currentResource = $scope.currentResource;
        }],
        view: ['$scope', function($scope) {
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense') {
              addCalculateExpense($scope);
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
        }],
        submissionEdit: ['$scope', function($scope) {
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense') {
              addCalculateExpense($scope);
            }
          });
          $scope.$watch('currentForm.form.name', function(formName) {
            if (formName === 'travelExpense2ndClinician') {
              addCalculateExpense($scope, 'submission.data');
            }
          });
        }]
      }
    });

    FormioResourceProvider.register('tripapply',AppConfig.resources.apply.form,{
      templates:{
        index : 'views/tripapply/index.html'
      },
      controllers: {
        index: ['$scope', function($scope) {

            var columns = [
              {
                name: 'Status',
                enableFiltering: false,
                width: 100,
                cellTemplate: '<div><p style="margin-left: 5px;"><a  href="#/trip/{{row.entity.data.trip._id}}/">{{row.entity.data.trip.data.status}}</a></p></div>'
              },
              {
                name: 'Diagnosis or Reason for Transport',
                enableFiltering: false,
                cellTemplate: '<div><p style="margin-left: 5px;white-space: nowrap;">{{row.entity.data.trip.data.diagnosis}}</p></div>'
              },
              {
                name: 'Gender Preferred',
                enableFiltering: false,
                width: 150,
                cellTemplate: '<div><p style="margin-left: 5px;white-space: nowrap;"><span ng-repeat="x in row.entity.data.trip.data.genderPreferred">{{x}}<span ng-show="row.entity.data.trip.data.genderPreferred.indexOf(x)< row.entity.data.trip.data.genderPreferred.length-1">, </span></p></div>'
              },
              {
                name: 'Patient Location',
                enableFiltering: false,
                cellTemplate: '<div><p style="margin-left: 5px;white-space: nowrap;">{{row.entity.data.trip.data.from.formatted_address}}</p></div>'
              },
              {
                name: 'Patient Destination',
                enableFiltering: false,
                cellTemplate: '<div><p style="margin-left: 5px;white-space: nowrap;">{{row.entity.data.trip.data.to.formatted_address}}</p></div>'
              },{
                name: 'Application Status',
                enableFiltering: false,
                cellTemplate: '<div><p style="margin-left: 5px;">{{row.entity.data.status || \'pending\'}}</p></div>'
              }
            ];

          $scope.gridOptions = {
            columnDefs: columns
          };

        }]
      }
    });

    // Add a quote controller under clients.
    var QuoteController = $injector.get('QuoteResourceProvider');
    QuoteController.parent = 'client';
    QuoteController.base = 'client.';
    FormioResourceProvider.register('clientQuote', AppConfig.resources.quote.form, QuoteController);

    $urlRouterProvider.otherwise('/');
  }

})();

/*global moment*/
(function() {
  'use strict';

  angular
    .module('skynurses')
    .constant('moment', moment);

})();

(function() {
  'use strict';

  angular
    .module('skynurses')
    .config(config);

  /** @ngInject */
  function config(
    $logProvider,
    toastrConfig,
    FormioProvider,
    FormioAuthProvider,
    formioComponentsProvider,
    $locationProvider,
    AppConfig
  ) {
    $locationProvider.hashPrefix('');
    FormioProvider.setBaseUrl(AppConfig.apiUrl);
    FormioAuthProvider.setAppUrl(AppConfig.appUrl);
    FormioAuthProvider.setStates('auth.portal', 'home');
    FormioAuthProvider.setForceAuth(['auth.customer','auth.business','auth.portal']);

    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;

    // Duck punch date to force all dates to display as UTC.
    formioComponentsProvider.register('datetime', {
      tableView: function (data, component, $interpolate) {
        return $interpolate('<span>{{ "' + data + '" | date: "' + component.format + '" : "UTC" }}</span>')();
      }
    });
  }

})();
